import React from 'react'
import { navigate } from 'gatsby'

const PageLanguageRedirect = ({ location }) => {
    React.useLayoutEffect(() => {
        if (location != null && location.pathname != null) {
            navigate(`/is${location.pathname}`)
        } else {
            navigate(`/is/email-verification`)
        }
    }, [location])
    return null
}

export default PageLanguageRedirect
